import React from "react";

import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { Layout, BaseHead } from "../components";
import Theme from "../components/theme";

const LexiconPage = ({ data }: any) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <Layout>
      <main>
        <Grid container spacing={3}>
          {edges.map(
            ({
              node: {
                html,
                frontmatter: { title },
              },
            }: any) => (
              <Grid item xs={12}>
                <article>
                  <Paper sx={{ padding: Theme.spacing(2) }}>
                    <Typography variant="h4">{title}</Typography>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: html }}
                      variant="body2"
                    />
                  </Paper>
                </article>
              </Grid>
            )
          )}
        </Grid>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query Lexicon {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "lexicon" } } }) {
      edges {
        node {
          id
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = () => <BaseHead title="Lexique" />;

export default LexiconPage;
